import App from "./App";
import { connect } from "react-redux";

import { checkIfHasToken, handleSignOut } from "../../actions/index";

const mapStateToProps = (state) => ({
  loggedIn: state.index.loggedIn,
  showMenu: state.index.showMenu,
  token: state.index.token,
});

const mapDispatchToProps = {
  handleSignOut,
  checkIfHasToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
