import React, { useEffect , useState } from 'react';
import { load } from "./load";

const ConfigLoader = props => {
    const [loaded, setLoaded] = useState(false);
    const [config, setConfig] = useState({});

    useEffect(() => {
        load()
        .then(config => {
            setConfig(config);
            setLoaded(true);
        })
    }, [])

    if(!loaded) {
        return props.loading ? props.loading() : null;
    }

    return props.ready(config);
}

export default ConfigLoader;
