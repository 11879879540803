const config = {};
export default config;

function load() {

    if(process.env.NODE_ENV === 'development') {
        return import('../config/config.json')
          .then(newconfig => {
              for(let prop in config) {
                  delete config[prop]
              }
              for(let prop in newconfig) {
                  config[prop] = newconfig[prop]
              }
              return config;
          });
    }

    return fetch('/config.json')
    .then(result => result.json())
    .then((newconfig) => {
        for(let prop in config) {
            delete config[prop]
        }
        for(let prop in newconfig) {
            config[prop] = newconfig[prop]
        }
        return config;
    })
    .catch(error => {
        console.log(error)
        return config;
    });
}
export {load}
