import React from "react";
import { render } from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ConfigLoader from "./utils/ConfigLoader";
import App from "./components/app";
import configureStore from "./storeConfig/configureStore";
import "./styles/main.scss";
import i18next from "./utils/i18n";

// Axios
import { api } from "@n5now/ui-campaigns/modules/utils/apiInstance";
import { parseInterceptorMessages } from "@n5now/ui-campaigns/modules/utils/parseInterceptorMessages";
import { handleSignOut } from "./actions";
import { toast } from "react-toastify";

// Localization for moment
import moment from "moment";
import "moment/locale/es";
moment.locale(i18next.language);

const store = configureStore();

const axios = api;
let pendingRequest = 0;

axios.interceptors.request.use(
  (req) => {
    pendingRequest++;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    pendingRequest--;
    return response;
  },
  (error) => {
    pendingRequest--;
    if (error && error.response && error.response.status) {
      if (error.response.status === 401) {
        const { url } = error.config || { url: "" };

        if (url.toLowerCase().indexOf("login") >= 0) {
          throw error;
        }

        if (pendingRequest === 0) {
          toast.error(
            "Tu sesión ha vencido, por favor inicia sesión nuevamente",
            {
              className: "errorToast",
              hideProgressBar: true,
            }
          );
        } else {
          store.dispatch(handleSignOut());
        }
        return new Promise(() => {});
      } else {
        return parseInterceptorMessages(
          error.response.status,
          () => {
            store.dispatch(handleSignOut());
          },
          error
        );
      }
    }

    throw error;
  }
);

console.warn("Version de la aplicación Build: 55129. Fecha 2023-12-22 15:58:35+00:00.");

render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConfigLoader ready={(config) => <App config={config} />} />;
      </MuiPickersUtilsProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
