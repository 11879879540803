import React, { Component } from 'react';

import Drawer from '@material-ui/core/Drawer';

import CampaignMenu from '../campaigns/CampaignMenu';
import AnalyticsMenu from '../analytics/AnalyticsMenu';

import styles from './Menu.module.scss';

class Menu extends Component {

  getMenu = () => {
    if (this.props.isAnalytics) {
      return <AnalyticsMenu />
    } else if (this.props.isCampaign) {
      return <CampaignMenu />
    }
  }

  render() {

    return (
      <div className={styles.mainContainer}>
        <Drawer
          className={styles.drawer}
          variant="permanent"
          classes={{
            paper: styles.drawerPaper,
          }}
          anchor="left"
        >
          {this.getMenu()}
        </Drawer>
      </div>
    )
  }
}

export default Menu;