import { toast } from "react-toastify";
import Cookies from "js-cookie";

import { jwtDecoder } from "../../utils/jwtDecoder";

export const signInError = (state, payload) => {
  if (payload.message === "Network Error") {
    toast.error(
      "Error de conexión, vuelva a internarlo y asegúrese que tenga una conexión a internet activa.",
      {
        className: "errorToast",
        hideProgressBar: true,
      }
    );
    return {
      ...state,
      isSigninIn: false,
    };
  } else if (
    payload.response.data.title === "One or more validation errors occurred."
  ) {
    const data = Object.values(payload.response.data.errors);
    const merged = [].concat.apply([], data);

    return merged.map((item) =>
      toast.error(item, {
        className: "errorToast",
        hideProgressBar: true,
      })
    );
  } else {
    toast.error(payload.response.data.title, {
      className: "errorToast",
      hideProgressBar: true,
    });
  }

  return {
    ...state,
    isSigninIn: false,
    signError: true,
  };
};

export const defineMenu = (state, payload) => {
  if (payload === "campaign") {
    return {
      ...state,
      isCampaign: true,
      isAnalytics: false,
    };
  }

  return {
    ...state,
    isAnalytics: true,
    isCampaign: false,
  };
};

export const setQueryBuilderData = (state, payload) => {
  let parsedData = payload;

  if (payload.query.includes("( AND")) {
    parsedData.query = payload.query.replace("AND (", "(");
  } else if (payload.query.includes("( OR")) {
    parsedData.query = payload.query.replace("OR (", "(");
  } else if (payload.query.includes("AND ()")) {
    parsedData.query = payload.query.replace("AND ()", "(");
  } else if (payload.query.includes("OR ()")) {
    parsedData.query = payload.query.replace("OR ()", "(");
  }

  return {
    ...state,
    queryBuilderData: parsedData,
  };
};

export const singnInSuccess = (state, payload) => {
  const currentCookie = jwtDecoder(payload.data.token);

  Cookies.set("name", currentCookie.name || "No Name");
  const token = Cookies.set("token", payload.data.token || "");

  return {
    ...state,
    token,
    loggedIn: true,
    isSigninIn: false,
  };
};

export const checkIfHasToken = (state, payload) => {
  const token = Cookies.get("token");
  if (token) {
    return {
      ...state,
      loggedIn: true,
      isSigninIn: false,
    };
  } else {
    return {
      ...state,
      loggedIn: false,
      issSigninIn: false,
    };
  }
};

export const handleSignOut = (state, payload) => {
  Cookies.remove("token", { path: "/" });
  Cookies.remove("name", { path: "/" });

  return {
    ...state,
    loggedIn: false,
    token: null,
  };
};

export const fetchImpactError = (state, payload) => {
  toast.error("We could not fetch the impact, please try again later", {
    className: "errorToast",
    hideProgressBar: true,
  });

  return {
    ...state,
    isFetchingImpact: false,
  };
};
