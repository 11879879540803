import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  logo: {
    '&>img': {
      height: ({ heightLogo }) => `${heightLogo}px`
    }
  },
  root: {
    pointerEvents: 'none',
    height: 'auto !important',
    top: '0 !important',
    padding: '16px 0 !important',
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));
