import React, { Fragment } from "react";
import { withRouter } from "react-router";

import CampaignHomeDashboard from "@n5now/ui-campaigns/modules/Campaigns/CampaignDashboard";

import FloatMenu from "@n5now/ui-toolkit/components/Layout/FloatMenu";
import config from "../../../utils/load";
import Cookies from "js-cookie";

const CampaignHome = (props) => {
  const logoutCallback = () => {
    props.checkIfHasToken();
  };

  const handleCreateAudience = () => {
    props.history.push("/campaigns/segments/new-segment");
  };

  const handleCreateCta = () => {
    props.history.push("/campaigns/ctas/new-cta");
  };

  const handleCreateCampaigns = () => {
    props.history.push("/campaigns/new-campaign");
  };

  const actions = [
    { title: "Crear Segmento", action: handleCreateAudience, icon: "star" },
    { title: "Crear CTA", action: handleCreateCta, icon: "star" },
    { title: "Crear Campaña", action: handleCreateCampaigns, icon: "star" },
  ];

  return (
    <Fragment>
      <FloatMenu actions={actions} />
      <CampaignHomeDashboard
        config={{
          API_URL: config.BASE_URL,
          BASE_URL_SCHEDULER: config.BASE_URL_SCHEDULER,
          AUTH_TOKEN: Cookies.get("token"),
          logoutCallback,
        }}
      />
    </Fragment>
  );
};

export default withRouter(CampaignHome);
