import React, {Fragment} from 'react';

import ToolSettingsDashboard from '@n5now/ui-campaigns/modules/ToolSettings/ToolSettingsDashboard';

import config from '../../../utils/load';
import Cookies from 'js-cookie';

const ToolSettings = (props) => {
  return (
    <Fragment>
      <ToolSettingsDashboard
        {...props}
        config={{
          API_URL: config.BASE_URL,
          AUTH_TOKEN: Cookies.get("token"),
        }}
      />
    </Fragment>
  );
};

export default ToolSettings;
