import axios from "@n5now/ui-campaigns/modules/utils/apiInstance";
import Cookies from 'js-cookie';
import config from '../utils/load'

export const parseAPI = (route, method, payload, id) => {
  const baseUrl = config.BASE_URL_USER;
  const header = {
    Authorization: `Bearer ${Cookies.get("token")}`,
    'Access-Control-Allow-Origin': '*'
  }

  if (method === "POST") {
    return axios.post(`${baseUrl}${route}`, { ...payload }, { headers: header })
      .then()
      .catch()
  } else if (method === "PUT") {
    return axios.put(`${baseUrl}${route}/${id}`, { ...payload }, { headers: header })
      .then()
      .catch()
  } else if (method === "GET" && payload) {
    return axios.get(`${baseUrl}${route}/${payload}`, { headers: header })
      .then()
      .catch()
  } else if (method === "DELETE" && id) {
    return axios.delete(`${baseUrl}${route}/${id}`, { headers: header })
      .then()
      .catch()
  } else if (method === "DELETE") {
    return axios.delete(`${baseUrl}${route}`, { headers: header })
      .then()
      .catch()
  } else {
    return axios.get(`${baseUrl}${route}`, { headers: header })
      .then()
      .catch()
  }
}