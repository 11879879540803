import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import * as ES from './resources/es'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    detection: {
      // order and from where user language should be detected
      order: ['navigator', 'cookie', 'localStorage', 'sessionStorage'],

      // keys or params to lookup language from
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage', 'cookie'],

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    defaultNS: 'common',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
    resources: {
      es: ES,
    },
  })

// Note: If this gets modified on the dev console on chrome
// or anywhere in the application it will break translations
// for Document Manager and or ToolKit
window.i18n = i18n
export default i18n
