import React from 'react';
import Menu from '@material-ui/core/Menu';
import { withRouter } from 'react-router-dom';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { useStyles } from './styles';
import config from '../../utils/load';
import { CONSTANTS } from "../../constants/constants";
import { useImage } from '../../utils/customHooks/useImage';

const AppBar = (props) => {
  const classes = useStyles({
    heightLogo: config.THEME.BAR_LOGO_HEIGHT
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [barLogoImage] = useImage(config.THEME.BAR_LOGO);

  return (
    <div>
      <MuiAppBar
        className={classes.root}
        color='transparent'
        style={{
          boxShadow: 'none',
          background: config.THEME.BAR_COLOR || '#F8F8F8',
          zIndex: 99
        }}
      >
        <Toolbar>
          <div className={classes.logo}>
            <img
              onClick={() => props.history.push(config.MODULE === CONSTANTS.modules.CAMPAIGNS
                ? '/campaigns'
                : '/analytics/models')}
              alt={barLogoImage}
              src={barLogoImage}
              style={{ pointerEvents: 'all', cursor: 'pointer' }}
            />
          </div>
          <div className={classes.title} />
          <div>
            <IconButton
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
              style={{ pointerEvents: 'all' }}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => props.handleSignOut()}>
                Sign Out
              </MenuItem>
              <MenuItem onClick={() => props.history.push('/users-config')}>
                User Config
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </MuiAppBar>
    </div>
  );
};

export default withRouter(AppBar);
