import React from "react";
import CreateCta from "@n5now/ui-campaigns/modules/CTAs/CreateCtaContainer";
import theme from "../../../../styles/theme/material-ui-theme.js";
import Cookies from "js-cookie";
import config from "../../../../utils/load";

const CreateCtaContainer = (props) => {
  const logoutCallback = () => {
    props.checkIfHasToken();
  };

  const handleCreateAudience = () => {
    props.history.push("/campaigns/segments/new-segment");
  };

  const handleCreateCta = () => {
    props.history.push("/campaigns/ctas/new-cta");
  };

  const handleCreateCampaigns = () => {
    props.history.push("/campaigns/new-campaign");
  };

  const actions = [
    { title: "Crear Segmento", action: handleCreateAudience, icon: "star" },
    { title: "Crear CTA", action: handleCreateCta, icon: "star" },
    { title: "Crear Campaña", action: handleCreateCampaigns, icon: "star" },
  ];

  const handleSuccess = () => {
    props.history.push("/campaigns/ctas");
  };

  return (
    <>
      {/* <FloatMenu actions={actions} /> */}
      <CreateCta
        {...props}
        config={{
          API_URL: config.BASE_URL,
          AUTH_TOKEN: Cookies.get("token"),
          theme: theme,
          logoutCallback,
        }}
        handleSuccess={handleSuccess}></CreateCta>
    </>
  );
};

export default CreateCtaContainer;
