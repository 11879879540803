import React from "react";

// TYPES


// MODALS


const Modal = props => {
  if (!props.isOpen) return null;

  const ModalContent = (() => {
    switch (props.type) {
      default:
        return null;
    }
  })();

  return (
    <div>
      <ModalContent {...props} />
    </div>
  );
};

export default Modal;
