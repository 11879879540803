import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { IconButton } from '@n5now/ui-toolkit';
import styles from "./AnalyticsMenu.module.scss";

class AnalyticsMenu extends Component {
    render() {
        return (
            <div className={styles.mainContainer}>
                <NavLink to="/analytics/models" className={styles.link}>
                    <IconButton
                        icon="model_training"
                        variant="contained"
                        title="Modelos"
                        color={
                            this.props.location.pathname.includes("models")
                                ? 'primary'
                                : 'default'
                        }
                        className={styles.icon}
                    />
                </NavLink>
                {/* <NavLink to="/analytics/models/datasets" className={styles.link}>
                    <IconButton
                        onClick={() => localStorage.setItem('datasets', true)}
                        icon="table_view"
                        variant="contained"
                        title="Datasets"
                        color="primary"
                        className={styles.icon}
                    />
                </NavLink> */}
            </div>
        );
    }
}

export default withRouter(AnalyticsMenu);
