import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import config from '../../utils/load';
import { CONSTANTS } from "../../constants/constants";
class RedirectManager extends Component {
  render() {
    return(
      <div>
        {
          this.props.loggedIn ?
          this.props.history.push(config.MODULE === CONSTANTS.modules.CAMPAIGNS
            ? '/campaigns'
            : '/analytics/models') : this.props.history.push("/login")
        }
      </div>
    )
  }
}

export default withRouter(RedirectManager);