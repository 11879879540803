import React, {useState, useEffect} from "react";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {Button, Card} from "@n5now/ui-toolkit";
import config from '../../utils/load';

import "./Login.scss";
import {useImage} from "../../utils/customHooks/useImage";
import {useStyles} from "./styles";
import {CONSTANTS} from "../../constants/constants";

const Login = ({signIn, loggedIn, history, isSigninIn}) => {
  const [value, setValue] = useState('');
  const [password, setPassword] = useState('');

  const [loginLogoImage] = useImage(config.THEME.LOGIN_LOGO);

  const classes = useStyles({
    logoWidth: config.THEME.LOGIN_LOGO_WIDTH,
  });

  const handleUser = (event) => {
    setValue(event.target.value)
  }

  const handleSignIn = () => {
    signIn({userName: value, password})
  }

  useEffect(() => {
    const moduleRoute = config.MODULE === CONSTANTS.modules.CAMPAIGNS
      ? `/campaigns`
      : `/analytics/models`;
    loggedIn && history.push(moduleRoute);
  }, [loggedIn, history]);

  return (
    <div className="login-container">
      <Grid container className="full-height">
        <Grid item xs={6}/>
        <Grid item xs={6} className="opacity-container">
          <Card contentClassName="card-content" style={{height: ""}}>
            <div className={classes.companyLogo}>
              <img src={loginLogoImage} alt={loginLogoImage}/>
            </div>
            <div className="form-container">
              <div className="text-field">
                <TextField
                  inputProps={{
                    maxLength: 50,
                  }}
                  fullWidth
                  label="Usuario"
                  value={value}
                  onChange={handleUser}
                />
              </div>
              <div className="text-field">
                <TextField
                  inputProps={{
                    maxLength: 50,
                  }}
                  fullWidth
                  label="Clave"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <Button
                  onClick={handleSignIn}
                  loading={isSigninIn}
                  color="primary"
                  variant={"contained"}
                  disabled={!password.length || !value.length}
                >
                  Iniciar sesión
                </Button>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
