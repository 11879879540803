import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { IconButton } from "@n5now/ui-toolkit";

import styles from "./CampaignMenu.module.scss";

class CampaignMenu extends Component {
  render() {
    return (
      <div className={styles.mainContainer}>
        <NavLink to="/campaigns" className={styles.link}>
          <IconButton
            icon="home"
            title="Home"
            color={
              this.props.location.pathname === "/campaigns"
                ? "primary"
                : "default"
            }
            className={styles.icon}
          />
        </NavLink>
        <NavLink to="/campaigns/list" className={styles.link}>
          <IconButton
            icon="campaign"
            title="Campañas"
            color={
              this.props.location.pathname.includes("campaigns/new-campaign") ||
              this.props.location.pathname.includes("campaigns/list")
                ? "primary"
                : "default"
            }
            className={styles.icon}
          />
        </NavLink>
        <NavLink to="/campaigns/my-campaigns" className={styles.link}>
          <IconButton
            icon="emoji_people"
            title="Mis Campañas"
            color={
              this.props.location.pathname === "/campaigns/my-campaigns"
                ? "primary"
                : "default"
            }
            className={styles.icon}
          />
        </NavLink>
        <NavLink to="/campaigns/segments" className={styles.link}>
          <IconButton
            icon="groups"
            variant="contained"
            title="Segmentos"
            color={
              this.props.location.pathname.includes("segments")
                ? "primary"
                : "default"
            }
            className={styles.icon}
          />
        </NavLink>
        <NavLink to="/campaigns/ctas" className={styles.link}>
          <IconButton
            icon="post_add"
            variant="contained"
            title="CTAs"
            color={
              this.props.location.pathname.includes("cta")
                ? "primary"
                : "default"
            }
            className={styles.icon}
          />
        </NavLink>
        <NavLink to="/campaigns/tool-settings" className={styles.link}>
          <IconButton
            icon="settings_suggest"
            variant="contained"
            title="Configuración"
            color={
              this.props.location.pathname.includes("tool-settings")
                ? "primary"
                : "default"
            }
            className={styles.icon}
          />
        </NavLink>
      </div>
    );
  }
}

export default withRouter(CampaignMenu);
