import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Login from '../components/login';
import RedirectManager from '../components/redirectManager';
import HomeAnalytics from '../components/analytics/AnalyticsContainer';
import PrivateRoute from './privateroute';

import HomeCampaigns from '../components/campaigns/CampaignContainer';
import UsersAdminContainer from "../components/users/UsersAdminContainer";

class Routes extends Component {

  render() {
    return (
      <Switch>
        <Route exact path={process.env.PUBLIC_URL + '/'} render={() =>
          <RedirectManager
            history={this.props.history}
            pathName={this.props.history.location.pathname} />}
        />
        <Route path="/login" component={Login} />

        <PrivateRoute path="/analytics" component={HomeAnalytics} />
        <PrivateRoute path="/campaigns" component={HomeCampaigns} />
        <PrivateRoute path="/users-config" component={UsersAdminContainer} />

      </Switch>
    )
  }
}



export default withRouter(Routes);