import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom';

import Cookies from 'js-cookie';

class PrivateRoute extends Component {
  render() {
    const token = Cookies.get('token');
    const { loggedIn ,component: Component, ...rest } = this.props
    if (loggedIn) {
      return <Route {...rest} render={props => <Component {...props} />} />
    }

    return (
      <Redirect to={!token ? "/login" : rest.location.pathname} />
    )
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

export default PrivateRoute;