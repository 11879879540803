import React from "react";
import { withRouter } from "react-router";
import theme from "../../../../styles/theme/material-ui-theme.js";
import { SegmentsDashboard } from "@n5now/ui-campaigns";
import Cookies from "js-cookie";
import config from "../../../../utils/load";
import FloatMenu from "@n5now/ui-toolkit/components/Layout/FloatMenu";

const SegmentsContainer = (props) => {
  const handleCreateAudience = () => {
    props.history.push("/campaigns/segments/new-segment");
  };

  const handleCreateCta = () => {
    props.history.push("/campaigns/ctas/new-cta");
  };

  const handleCreateCampaigns = () => {
    props.history.push("/campaigns/new-campaign");
  };

  const actions = [
    { title: "Crear Segmento", action: handleCreateAudience, icon: "star" },
    { title: "Crear CTA", action: handleCreateCta, icon: "star" },
    { title: "Crear Campaña", action: handleCreateCampaigns, icon: "star" },
  ];

  return (
    <>
      {/* <FloatMenu actions={actions} /> */}
      <SegmentsDashboard
        {...props}
        config={{
          API_URL: config.BASE_URL,
          AUTH_TOKEN: Cookies.get("token"),
          theme: theme,
        }}
        handleRoute={handleCreateAudience}></SegmentsDashboard>
    </>
  );
};

export default withRouter(SegmentsContainer);
