import React from "react";
import {UsersAdmin, Typography} from "@n5now/ui-toolkit"
import Grid from '@material-ui/core/Grid'
import Cookies from "js-cookie";

const UsersAdminContainer = ()=>{
  return(
    <Grid container>
      <Grid item md="12">
        <Typography variant="h3" color="secondary">Users</Typography>
      </Grid>
      <Grid item md="12">
        <br/>
        <UsersAdmin
          config={{
            API_URL: 'https://private-34dd85-analytics45.apiary-mock.com/api/v1/analytics/',
            AUTH_TOKEN: Cookies.get("token")
          }}
        />
      </Grid>
    </Grid>
  )
}

export default UsersAdminContainer