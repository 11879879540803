import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { toolkitES, toolkitEN } from "@n5now/ui-toolkit"

i18n.use(initReactI18next).init({
  fallbackLng: "es",
  defaultNS: "common",
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
  resources: {
    es: {
      toolkit: {
        ...toolkitES,
      },
    },
    en: {
      toolkit: {
        ...toolkitEN,
      },
    },
  },
});

// Note: If this gets modified on the dev console on chrome or anywhere in the application it will break translations
// for Document Manager and or ToolKit
window.i18n = i18n;
export default i18n;
