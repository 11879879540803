import { createTheme } from '@material-ui/core/styles'
import styleVariables from '../_variables.module.scss';

const theme = createTheme({
  typography: {
    fontFamily: "Muli",
    useNextVariants: true,
    h6: {
      fontSize: 18,
      color: 'gray',
      fontWeight: 'bold',
    },
    body1: {
      fontFamily: 'Muli'
    }
  },
  palette: {
    primary: {
      light: styleVariables.primaryLight,
      main: styleVariables.primaryColor,
      dark: styleVariables.primaryDark,
    },
    secondary: {
      main: styleVariables.secondary,
      red: styleVariables.red,
    }
  }
})

export default theme;
