import React, { useEffect, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import AnalyticsHome from '../AnalyticsHome';
import AnalyticsNewModel from '../AnalyticsNewModel/AnalyticsNewModel';
import AnalyticsDatasets from '../AnalyticsDatasets';

const AnalyticsContainer = props => {
  useEffect(() => {
      props.defineMenu("analytics");
  }, [props])

  return (
    <Fragment>
      <Switch>
        <Route exact path="/analytics/models" component={AnalyticsHome} />
        <Route exact path="/analytics/models/new-model" component={AnalyticsNewModel} />
        <Route exact path="/analytics/models/datasets" component={AnalyticsDatasets} />
      </Switch>
    </Fragment>
  )
};

export default AnalyticsContainer;