import React, {Fragment} from 'react';

import CatalogCreate from '@n5now/ui-campaigns/modules/ToolSettings/Catalog/CatalogEdit';
import config from '../../../utils/load';
import Cookies from 'js-cookie';


const CatalogContainer = (props) => {
  // HANDLERS
  const handleSuccess = () => {
    props.history.push("/campaigns/tool-settings/");
  };

  return (<Fragment>
    <CatalogCreate
      {...props}
      config={{
        API_URL: config.BASE_URL,
        AUTH_TOKEN: Cookies.get('token'),
      }}
      handleSuccess={handleSuccess}
    />
    </Fragment>)
};

export default CatalogContainer;
