import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
//actions
import { 
  SIGN_IN,
  signInSuccess,
  signInError,
} from '../actions/index';


import { parseAPI } from '../serverAPI/parseAPI';
import config from '../utils/load';

//epics
export const signInEpic = action$ =>
  action$.pipe(
    ofType(SIGN_IN),
    mergeMap(({payload}) =>
      parseAPI(config.ENDPOINT_LOGIN, "POST", payload)
        .then(signInSuccess)
        .catch(signInError)
    ));

