import React, {Fragment} from 'react';

import SettingsFilterEdit from '@n5now/ui-campaigns/modules/ToolSettings/SettingsFilterEdit';

import config from '../../../utils/load';
import Cookies from 'js-cookie';

const CreateSettingsFilterContainer = (props) => {
  const handleSuccess = () => {
    props.history.push("/campaigns/tool-settings/");
  };

  return (
    <Fragment>
      <SettingsFilterEdit
        {...props}
        config={{
          API_URL: config.BASE_URL,
          AUTH_TOKEN: Cookies.get("token"),
        }}
        handleSuccess={handleSuccess}
      />
    </Fragment>
  );
};

export default CreateSettingsFilterContainer;
