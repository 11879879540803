import { connect } from 'react-redux';

import AnalyticsContainer from './AnalyticsContainer';

import { defineMenu } from '../../../actions';

const mapDispatchToProps = {
    defineMenu,
}

export default connect(null, mapDispatchToProps)(AnalyticsContainer);