import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import index from './index';

import {
    signInEpic,
} from '../epics/index';

const rootEpic = combineEpics(
    signInEpic,
);

const rootReducer = combineReducers({
    index,
});

export {
    rootEpic,
    rootReducer
}