import Modal from "./Modal";
import {connect} from 'react-redux';

import {manageModal} from '../../actions/index';

const mapStateToProps = state => ({
    isOpen: state.index.isOpen,
    ...state.index.modal,
});

export default connect(mapStateToProps, {manageModal})(Modal);
