import React, {Component} from "react";
import {ToastContainer} from "react-toastify";
import {I18nextProvider} from "react-i18next";
import classnames from "classnames";
import "react-toastify/dist/ReactToastify.css";

import {
  faSave,
  faPlay,
  faTasks,
  faChartLine,
  faInfoCircle,
  faFolderOpen,
  faQuestionCircle,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

import { Theme, WithTheme } from "@n5now/ui-toolkit";
import i18n from "../../locale";
import Modal from "../modals";
import Menu from "../menu";
import AppBar from "../appBar";
import Routes from "../../router/routes";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import {createGenerateClassName, StylesProvider} from "@material-ui/core";

library.add(
  faPlay,
  faSave,
  faTasks,
  faChartLine,
  faFolderOpen,
  faInfoCircle,
  faQuestionCircle,
  faArrowCircleRight
);

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: true,
});

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      theme: Theme,
    }
  }

  async componentDidMount() {
    this.props.checkIfHasToken();
    const { config } = this.props;

    document.title = config.THEME.TITLE;
    const toolkit = await import('@n5now/ui-toolkit');
    const splashLoginScreen = await import(`../../assets/images/${config.THEME.SPLASH_LOGIN}`);

    const styles = {
      backgroundImage: `url(${splashLoginScreen.default})`,
    };

    this.setState({
      theme: toolkit[config.THEME.NAME],
      styles,
    });
  }

  render() {
    const { loggedIn, showMenu } = this.props;
    const { theme, styles } = this.state;

    return (
      <Router>
        <I18nextProvider i18n={i18n}>
          <div className="App">
            <div
              className={classnames("App-header", {
                "App-header-login": !loggedIn,
              })}
              style={!loggedIn ? styles : {}}
            >
              <StylesProvider generateClassName={generateClassName}>

                <WithTheme theme={theme()}>
                  {loggedIn && (
                    <div>
                      <AppBar/>
                      <div className={classnames({hidden: !showMenu})}>
                        <Menu/>
                      </div>
                    </div>
                  )}
                  <div
                    className={classnames("main-content", {
                      "loggin-main-content": !loggedIn,
                      "hidden-styles": !showMenu,
                    })}
                  >
                    <Routes/>
                    <Modal/>
                    <ToastContainer/>
                  </div>
                </WithTheme>
              </StylesProvider>
            </div>
          </div>
        </I18nextProvider>
      </Router>
    )
  }
}

export default App;
