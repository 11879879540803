import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,

  MANAGE_MODAL,

  RESET_VARIABLES,

  MANAGE_MENU,

  DEFINE_MENU,

  SET_QUERY_BUILDER_DATA,

  CLEAN_QUERY_OBJECT,

  CHECK_IF_HAS_TOKEN,

  HANDLE_SIGN_OUT,

  FETCH_IMPACT,
  FETCH_IMPACT_SUCCESS,
  FETCH_IMPACT_ERROR,
} from '../actions/index';

import {
  defineMenu,
  signInError,
  setQueryBuilderData,
  singnInSuccess,
  checkIfHasToken,
  handleSignOut,
  fetchImpactError,
} from './utils';

const initialState = {
  loggedIn: false,
  token: null,
  isCampaign: false,
  isAnalytics: false,
  isSigninIn: false,
  isOpen: false,
  modal: {},
  errors: [],
  signError: false,
  showMenu: true,
  queryBuilderData: {},
  isFetchingImpact: false,
  impactNumber: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSigninIn: true,
      }

    case SIGN_IN_SUCCESS:
      return singnInSuccess(state, action.payload);

    case SIGN_IN_ERROR:
      return signInError(state, action.payload);

    case MANAGE_MODAL:
      return {
        ...state,
        ...action.payload,
        isOpen: !state.isOpen,
      }

    case RESET_VARIABLES:
      return {
        ...state,
        signError: false,
      }

    case MANAGE_MENU:
      return {
        ...state,
        showMenu: !state.showMenu,
      }

    case DEFINE_MENU:
      return defineMenu(state, action.payload);

    case SET_QUERY_BUILDER_DATA:
      return setQueryBuilderData(state, action.payload);

    case CLEAN_QUERY_OBJECT:
      return {
        ...state,
        queryBuilderData: {}
      }

    case CHECK_IF_HAS_TOKEN:
      return checkIfHasToken(state, action.payload);

    case HANDLE_SIGN_OUT:
      return handleSignOut(state, action.payload);

    case FETCH_IMPACT:
      return {
        ...state,
        isFetchingImpact: true,
      }

    case FETCH_IMPACT_SUCCESS:
      return {
        ...state,
        impactNumber: action.payload,
        isFetchingImpact: false,
      }

    case FETCH_IMPACT_ERROR:
      return fetchImpactError(state, action.payload);

    default:
      return state;
  }
}