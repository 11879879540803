import React, { Component } from 'react';
import { Typography, BreadCrumbs, Grid, SDTheme, Theme } from '@n5now/ui-toolkit'
import { CreateModel } from '@n5now/ui-analytics'
import Cookies from 'js-cookie'
import config from '../../../utils/load';

const items = [
	{
		label: 'Home',
		href: '/',
		onClick: item => console.log(item)
	},
	{
		label: 'Analytics',
		href: '/analytics',
		onClick: item => console.log(item)
	},
	{
		label: 'Models',
		href: '/analytics/models',
	},
	{
		label: 'Create Model',
	}
]

class AnalyticsNewModel extends Component {
	render() {
		return (
			<Grid container direction="column">
				<Typography variant="h3" color="secondary">Create Model</Typography>
				<BreadCrumbs items={items}/>
				<CreateModel
					config={{
						API_URL: config.BASE_URL,
						AUTH_TOKEN: Cookies.get("token"),
						parentProps: this.props,
						theme: Theme
					}}
				/>
			</Grid>
		)
	}
}

export default AnalyticsNewModel;
