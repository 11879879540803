import Login from './Login';
import { connect } from 'react-redux';
import { signIn, resetVariables } from '../../actions/index';

const mapStateToProps = state => ({
  isSigninIn: state.index.isSigninIn,
  loggedIn: state.index.loggedIn,
  signError: state.index.signError,
});

const mapDispatchToProps = {
  signIn,
  resetVariables,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);