import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import CampaignHome from "../CampaignHome";
import SegmentsContainer from "../Segments/SegmentsContainer";
import CreateSegmentContainer from "../Segments/CreateSegmentContainer";
import CreateCtaContainer from "../CTAs/CreateCtaContainer";
import CtasContainer from "../CTAs/CtasContainer";
import CreateCampaignContainer from "../CreateCampaign";
import CampaignListContainer from "../CampaignListContainer";
import MyCampaignsContainer from "../MyCampaignsContainer";
import ToolSettingsContainer from "../ToolSettingsContainer";
import CreateSettingsFilterContainer from "../CreateSettingsFilterContainer";
import CatalogContainer from "../CatalogContainer";

class CampaignContainer extends Component {
  componentDidMount() {
    this.props.defineMenu("campaign");
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/campaigns" component={CampaignHome} />
          <Route
            exact
            path="/campaigns/my-campaigns"
            component={MyCampaignsContainer}
          />
          <Route
            exact
            path="/campaigns/segments"
            component={SegmentsContainer}
          />
          <Route
            exact
            path="/campaigns/segments/new-segment"
            component={CreateSegmentContainer}
          />
          <Route
            exact
            path="/campaigns/segments/:id"
            component={CreateSegmentContainer}
          />
          <Route exact path="/campaigns/ctas" component={CtasContainer} />
          <Route
            exact
            path="/campaigns/ctas/new-cta"
            component={CreateCtaContainer}
          />
          <Route
            exact
            path="/campaigns/ctas/:id"
            component={CreateCtaContainer}
          />
          <Route
            exact
            path="/campaigns/list"
            component={CampaignListContainer}
          />
          <Route
            exact
            path="/campaigns/new-campaign"
            component={CreateCampaignContainer}
          />
          <Route
            exact
            path="/campaigns/list/:id"
            component={CreateCampaignContainer}
          />
          <Route
            exact
            path={"/campaigns/tool-settings/:id"}
            component={CreateSettingsFilterContainer}
          />
          <Route
            exact
            path={"/campaigns/tool-settings/new-filter"}
            component={CreateSettingsFilterContainer}
          />
          <Route
            exact
            path={"/campaigns/tool-settings/catalog/create"}
            component={CatalogContainer}
          />
          <Route
            exact
            path="/campaigns/tool-settings"
            component={ToolSettingsContainer}
            />
        </Switch>
      </Fragment>
    );
  }
}

export default CampaignContainer;
