import {useEffect, useState,} from "react";

export const useImage = (imageName) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    import(`../../assets/images/${imageName}`)
      .then(loadedImage => {
        setImage(loadedImage.default)
      });
  }, [imageName]);

  return [image];
}
