//constants

export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";

export const MANAGE_MODAL = "MANAGE_MODAL";

export const RESET_VARIABLES = "RESET_VARIABLES";

export const MANAGE_MENU = "MANAGE_MENU";

export const DEFINE_MENU = "DEFINE_MENU";

export const SET_QUERY_BUILDER_DATA = "SET_QUERY_BUILDER_DATA";

export const CLEAN_QUERY_OBJECT = "CLEAN_QUERY_OBJECT";

export const CHECK_IF_HAS_TOKEN = "CHECK_IF_HAS_TOKEN";

export const HANDLE_SIGN_OUT = "HANDLE_SIGN_OUT";

export const FETCH_IMPACT = "FETCH_IMPACT";
export const FETCH_IMPACT_SUCCESS = "FETCH_IMPACT_SUCCESS";
export const FETCH_IMPACT_ERROR = "FETCH_IMPACT_ERROR";

//actions

export const signIn = payload => ({ type: SIGN_IN, payload });
export const signInSuccess = payload => ({ type: SIGN_IN_SUCCESS, payload });
export const signInError = payload => ({ type: SIGN_IN_ERROR, payload });

export const manageModal = modal => ({ type: MANAGE_MODAL, payload: { modal } });

export const resetVariables = () => ({ type: RESET_VARIABLES });

export const manageMenu = () => ({ type: MANAGE_MENU });

export const defineMenu = payload => ({ type: DEFINE_MENU, payload });

export const setQueryBuilderData = payload => ({ type: SET_QUERY_BUILDER_DATA, payload });

export const cleanQueryObject = () => ({ type: CLEAN_QUERY_OBJECT });

export const checkIfHasToken = () => ({ type: CHECK_IF_HAS_TOKEN });

export const handleSignOut = () => ({ type: HANDLE_SIGN_OUT });

export const fetchImpact = (inclusionFilters, exclusionFilters) => ({ type: FETCH_IMPACT, payload: { inclusionFilters, exclusionFilters } });
export const fetchImpactSuccess = payload => ({ type: FETCH_IMPACT_SUCCESS, payload });
export const fetchImpactError = payload => ({ type: FETCH_IMPACT_ERROR, payload });


